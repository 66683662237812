import React, { useContext } from "react";
import Card from "../components/card";
import style from "../styles/subscription-card.module.css";
import Button from "./button";
import FormattedMsg from "../locale/components/formatted-msg";
import Chevron from "./chevron";
import iconStyle from "../styles/icon.module.css";
import { ThemeContext } from "../contexts/theme-context";
import OverlayCard from "./overlay-card";
import { OverlayContext } from "../contexts/overlay-context";
import Divider from "./divider";
import Logger from "./logger/logger";
import { TickIcon } from "./icons/tick-icon";

const generateTableSection = (section, tag) => {
  const titles = {
    included_services: "Included_services_section_title",
    contract_info: "Contract_information_section_title",
    other_info: "Other_information_section_title",
  };

  return (
    <tbody className={style.detailsSection} key={"section-" + tag}>
      <tr>
        <th className={style.detailsHeader} colSpan={2} key={"header-" + tag}>
          <FormattedMsg id={titles[tag]} />
        </th>
      </tr>
      {Object.values(section).map(
        (r, i) =>
          r && (
            <tr className={style.detailsRow} key={r.key + i}>
              <td className={style.detailsTableTitle} key={"key-" + tag + i}>
                {r.key + ":"}
              </td>
              <td
                className={style.detailsTableDescription}
                key={"value-" + tag + i}
              >
                <span>{r.value}</span>
                {r.notes && <p className={style.detailsNote}>{r.notes}</p>}
              </td>
            </tr>
          )
      )}
    </tbody>
  );
};
export const DetailsOverlay = (props) => {
  const { plan } = props;
  const text = {};
  const notesKey = "promotionDetails";
  plan.additionalTexts.forEach((r) => {
    if (r && r.tags[0]) {
      if (text[r.tags[0]]) text[r.tags[0]][r.key] = r;
      else if (r.tags[0] === notesKey) {
        // append the note to the linked row
        if (plan.hasPromotion) {
          const targetKey = r.tags[1];
          const targetSection = Object.keys(text).find((t) =>
            Object.keys(text[t]).includes(targetKey)
          );
          if (targetSection) {
            text[targetSection][targetKey].notes = r.value;
          } else {
            Logger.e("Expected to find key linked to note: ", r);
          }
        }
      } else text[r.tags[0]] = { [r.key]: r };
    }
  });

  return (
    <OverlayCard
      className={style.overlayContainer}
      close={props.close}
      data-testid="plan-details-overlay"
    >
      <div className={style.overlayContent}>
        <h1 className={style.detailsTitle}>
          {plan.commercialName && plan.serviceInformation && (
            <FormattedMsg
              id="Product_information_sheet_title"
              values={{
                planName: plan.commercialName,
                data: plan.serviceInformation,
              }}
            />
          )}
        </h1>
        <h2 className={style.planDetailsTitle}>
          <FormattedMsg id="Product_information_sheet_secondary_title" />
        </h2>
        <table className={style.detailsTable} data-testid="plan-details-table">
          {Object.keys(text).map((t) => generateTableSection(text[t], t))}
        </table>
      </div>
    </OverlayCard>
  );
};

const generateDetailsPreviewBanners = (details, theme, icons = true) => {
  let res = [];
  for (const index in details) {
    if (details[index])
      res.push(
        <div key={index} className={style["details-preview-banner-container"]}>
          <div className={style["details-preview-banner"]}>
            {icons && (
              <TickIcon
                variant={theme === "dark" ? "white" : "red"}
                className={style.tickIcon}
              />
            )}
            <p
              className={style["details-preview-text"]}
              data-testid={`selling-point-${Number(index) + 1}`}
            >
              {details[index]}
            </p>
          </div>
          {index < details.length - 1 ? <Divider /> : ""}
        </div>
      );
  }
  return res;
};

export const SubscriptionCard = (props) => {
  const { showPib, showPromotionDetails, plan, changeSubscription, className } =
    props;
  const {
    serviceInformation,
    commercialName,
    offerDescription,
    pricing,
    recurrency,
    keySellingPoints,
    moreInfoUrl,
    pricePlanId,
    promotionDetails,
    hasPromotion,
  } = plan;

  const { theme } = useContext(ThemeContext);
  const { showOverlay } = useContext(OverlayContext);
  return (
    <div className={`${style.cardContainer} ${className ?? ""}`}>
      <Card
        className={style.card}
        data-testid={props["data-testid"] || "subscription-card"}
      >
        <div className={style.header}>
          <h1 className={style.title}>
            {serviceInformation && (
              <span data-testid="service-information">
                {serviceInformation}
              </span>
            )}
            <span className={style.name} data-testid="commercial-name">
              {commercialName}
            </span>
          </h1>
          <p className={style["price-banner"]}>
            {pricing && (
              <>
                <span className={style.price} data-testid="pricing">
                  {pricing?.replace("EUR", "€")}
                </span>
                {recurrency && (
                  <span className={style["time-unit"]} data-testid="recurrency">
                    /{recurrency}
                  </span>
                )}
              </>
            )}
          </p>
        </div>
        <p
          className={style["free-trial-banner"]}
          data-testid="offer-description"
        >
          {offerDescription || ""}
        </p>

        <div
          className={style["details-preview-container"]}
          data-testid="key-selling-points"
        >
          {keySellingPoints &&
            generateDetailsPreviewBanners(keySellingPoints, theme)}
        </div>

        <div
          className={style["details-link-container"]}
          data-testid="see-more-details"
          onClick={() =>
            plan.additionalTexts && showOverlay("plan-details", { plan })
          }
        >
          {plan.additionalTexts && (
            <>
              <p
                className={style["details-link-text"]}
                data-testid="plan-details-label"
              >
                <FormattedMsg id="Plan_details_button" />
              </p>
              <Chevron
                className={`${iconStyle.icon} ${style.chevron}`}
                background={false}
                direction="right"
                data-testid="plan-details-chevron"
              />
            </>
          )}
        </div>
        <Button
          className={style["select-button"]}
          variant={changeSubscription ? "tertiary" : "primary"}
          data-link-id="select-plan-button"
          data-testid="subscription-card-button"
          eventMeta={
            !changeSubscription && pricePlanId
              ? { pricePlanId: pricePlanId }
              : undefined
          }
          onClick={props.onSelect}
        >
          <FormattedMsg
            id={
              changeSubscription
                ? "Change_subscription_button"
                : "Select_button"
            }
          />
        </Button>
      </Card>
      {showPib && moreInfoUrl && (
        <p className={style.pib}>
          <FormattedMsg
            id="Product_information_sheet_link"
            values={{
              link: (
                <Button
                  href={moreInfoUrl}
                  className={style.link}
                  data-testid="pib-link"
                  variant="link"
                  external
                >
                  <FormattedMsg
                    id="PiB_link_label"
                    values={{
                      planName: commercialName,
                    }}
                  />
                </Button>
              ),
            }}
          />
        </p>
      )}
      {showPromotionDetails && promotionDetails && hasPromotion && (
        <p className={style.notes}>{promotionDetails}</p>
      )}
    </div>
  );
};

export const SmallSubscriptionCard = (props) => {
  const { showPib, plan, className, selected, onClick } = props;
  const {
    serviceInformation,
    commercialName,
    offerDescription,
    pricing,
    recurrency,
    keySellingPoints,
    moreInfoUrl,
    hasPromotion,
  } = plan;

  const { theme } = useContext(ThemeContext);
  const { showOverlay } = useContext(OverlayContext);
  return (
    <div
      className={`${style.cardContainer} ${className ?? ""} ${
        style.smallCardContainer
      }`}
    >
      <Card
        className={`${selected ? style.selected : ""} ${style.card}`}
        data-testid={props["data-testid"] || "small-subscription-card"}
        onClick={onClick}
      >
        <div className={style.header}>
          <h1 className={style.title}>
            {serviceInformation && (
              <span data-testid="service-information">
                {serviceInformation}
              </span>
            )}
            <span className={style.name} data-testid="commercial-name">
              {commercialName}
            </span>
          </h1>
          <p className={style["price-banner"]}>
            {pricing && (
              <>
                <span className={style.price} data-testid="pricing">
                  {pricing?.replace("EUR", "€")}
                </span>
                {recurrency && (
                  <span className={style["time-unit"]} data-testid="recurrency">
                    /{recurrency}
                  </span>
                )}
              </>
            )}
          </p>
        </div>
        <p
          className={style["free-trial-banner"]}
          data-testid="offer-description"
        >
          {offerDescription || ""}
        </p>
        <div className={style["details-container"]}>
          <div
            className={style["details-preview-container"]}
            data-testid="key-selling-points"
          >
            {keySellingPoints &&
              generateDetailsPreviewBanners(keySellingPoints, theme, true)}
          </div>
        </div>

        <div className={style.footerDetails}>
          <div
            className={style["details-link-container"]}
            data-testid="see-more-details"
            onClick={(e) => {
              e.stopPropagation();
              plan.additionalTexts && showOverlay("plan-details", { plan });
            }}
          >
            {plan.additionalTexts && (
              <>
                <p
                  className={style["details-link-text"]}
                  data-testid="plan-details-label"
                >
                  <FormattedMsg id="Plan_details_button" />
                </p>
                <Chevron direction="right" className={style.chevron} />
              </>
            )}
          </div>
        </div>
      </Card>
      {showPib && moreInfoUrl && hasPromotion && (
        <p className={style.pib}>
          <FormattedMsg
            id="Product_information_sheet_link"
            className={style.pib}
            values={{
              link: (
                <Button
                  href={moreInfoUrl}
                  className={style.link}
                  data-testid="pib-link"
                  variant="link"
                  external
                >
                  <FormattedMsg
                    id="PiB_link_label"
                    values={{
                      planName: commercialName,
                    }}
                  />
                </Button>
              ),
            }}
          />
        </p>
      )}
    </div>
  );
};
