import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import Button from "../../../components/button";
import quotationsData from "../../../components/network/quotations";
import useNetwork from "../../../components/network/use-network";
import { SubscriptionCard } from "../../../components/subscription-card";
import ToggleSwitch from "../../../components/toggle-switch";
import ConfigContext from "../../../contexts/config-context";
import FormattedMsg from "../../../locale/components/formatted-msg";
import { ErrorPage } from "../../common/newSubscription/errorPage";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import { CallHandler } from "../../../components/call-handler";
import Logger from "../../../components/logger/logger";
import Carousel from "../../../components/carousel";
import Helpers from "../../../components/helpers";

import pageStyle from "../../../styles/page.module.css";
import carouselStyle from "../../../styles/carousel.module.css";

const generateCards = (plans, kind, onSelect) => {
  let res = [];
  for (const i in plans) {
    res.push(
      <SubscriptionCard
        plan={plans[i]}
        key={i}
        onSelect={() => onSelect(plans[i])}
        data-testid={"subscription-card-" + kind.replace(/\s/g, "-") + i}
        showPib={true}
      />
    );
  }
  return res;
};

const quotationsTag = "quotations";

const getNumberOfCards = () => {
  const width = Helpers.getWidth();
  return width < 768 ? 1 : 2;
};

const SelectPlan = (props) => {
  const navigate = useNavigate();
  const {
    setData,
    setQuotationsResult,
    getQuotationsData,
    formData: { selectPlan: data },
    pages,
  } = useOutletContext();
  const { startNetworkCall, checkNetworkCall, removeNetworkCall } =
    useNetwork();

  const { config } = useContext(ConfigContext);

  const [, setTick] = useState();
  const [cards, setCards] = useState(getNumberOfCards());
  const pathState = useLocation()?.state;
  const selectLink = pages.NewSubscription.PersonalInformation.full;

  useEffect(() => {
    const listener = window.addEventListener("resize", (e) => {
      setCards(getNumberOfCards());
    });
    return () => window.removeEventListener("resize", listener);
  }, []);

  const onSelect = (plan) => {
    // if it's the beginning of the flow, go to the next page,
    // or go back to the previous page otherwise
    const firstTime = !!!data.plan;

    setData("plan", plan, false);
    setData("submittable", true, false);

    // go to the following page if no redirect page was passed
    navigate(
      firstTime || !pathState?.redirectTo ? selectLink : pathState?.redirectTo
    );
  };
  const getQuotationsCall = checkNetworkCall(quotationsTag, false, false);

  const plans = getQuotationsData()?.plans;
  const error = getQuotationsData()?.error;

  // Extract the labels to use
  Logger.d(plans, error);
  let options = [
    ...new Set(!error ? plans?.map((p) => p.durationTag) : []),
  ].map((tag) => {
    const label = plans.find((p) => p.durationTag === tag).durationLabel;
    return tag ? { label, state: tag, tag } : null;
  });

  const [selected, select] = useState(
    data?.plan?.durationTag ?? options[0]?.durationTag
  );
  const handleToggleSwitch = (newState) => {
    select(newState.state);
  };

  // if no kind of contract is selected,
  // select the kind of the first one
  // or the one of the previously selected
  if (plans && !selected) {
    select(data?.plan?.durationTag ?? plans[0].durationTag);
  }

  const getQuotations = () => {
    //start Call
    setTick(Date.now());
    return startNetworkCall(
      quotationsTag,
      () => quotationsData(config),
      false,
      false
    );
  };

  const callback = (result) => {
    Logger.d(result, result instanceof Error);
    setQuotationsResult(result);
  };

  // call the API if the data hasn't been retrieved yet
  // (and no other call is going on)
  if (!plans && !error && !getQuotationsCall.isPending()) {
    getQuotations();
  }

  if (error) {
    const error = getQuotationsCall.read();
    const errorIDs = {
      primaryAction: () => {
        //reset call
        removeNetworkCall(quotationsTag, false);
        // call the API again
        getQuotations();
        //refresh
        setTick(Date.now());
      },
    };
    return <ErrorPage status={error?.status} {...errorIDs} />;
  }

  return (
    <NewSubscriptionPage
      heroTitle={{
        textId: "Hero_headline",
      }}
      applyMeasures={true}
      className={pageStyle.selectPlan}
    >
      {getQuotationsCall?.isPending() ? (
        <CallHandler call={getQuotationsCall} onFinish={callback} />
      ) : (
        <>
          {!options.includes(null) && (
            <ToggleSwitch
              options={options}
              selected={selected}
              onStateChange={handleToggleSwitch}
              data-testid="switch-plans"
            />
          )}
          <Carousel
            className={carouselStyle.selectPlan}
            data-testid="select-plan-carousel"
            cardsPerStep={cards}
            endless={cards === 1}
            alwaysWrap={true}
          >
            {generateCards(
              options.includes(null)
                ? plans
                : plans?.filter((p) => p.durationTag === selected),
              selected,
              onSelect
            )}
          </Carousel>
          <p className={pageStyle.pageDescription}>{plans?.[0].promotionDetails}</p>
          <Button
            className={pageStyle.centralButton}
            variant="secondary"
            data-link-id="GoBackButton"
            data-testid="back-button"
            onClick={() => {
              navigate(-1);
            }}
          >
            <FormattedMsg id="Secondary_action" />
          </Button>
        </>
      )}
    </NewSubscriptionPage>
  );
};

export default SelectPlan;
