import { useContext, useEffect, useState } from "react";
import MicrosoftStoreBadge from "../components/icons/microsoft-badge";
import LocaleContext from "../contexts/locale-context";
import { ErrorPage } from "./siegfried/newSubscription/errorPage";
import EventsHelper from "../components/tracking/events-helper";
import Logger from "../components/logger/logger";

import style from "../styles/pages/errorPage.module.css";
import ConfigHelper from "../config/config-helper";
import Overlays from "../components/overlays";

const EdgeHtmlRegex = /Edge\/([\d.]+)/;
const EdgeChromiumRegex = /Edg\/([\d.]+)/;
const minimumChromeVersion = "116.0.1938.62".split(".");

function checkEdgeHtml() {
  const matchresult = EdgeHtmlRegex.exec(navigator.userAgent);
  return matchresult?.length && matchresult.length > 1;
}

function checkChromiumVersion() {
  const matchresult = EdgeChromiumRegex.exec(navigator.userAgent);

  if (matchresult?.length && matchresult.length > 1) {
    //we have chromium, check Version
    const version = matchresult[1].split(".");
    const lastMin = minimumChromeVersion.length - 1;
    const lastVer = version.length - 1;
    for (let i = 0; i < minimumChromeVersion.length; i++) {
      try {
        const min = Number(minimumChromeVersion[i]);
        const ver = Number(version[i]);
        if (isNaN(min) || isNaN(ver)) {
          throw new Error("Either min or ver are NaN: " + min + ", " + ver);
        } else {
          if (min < ver) {
            //ok, matched;
            break;
          } else if (min > ver) {
            //no match
            return true;
          } else if (i === lastMin || i === lastVer) {
            //last digit, min === ver, match!
            break;
          } else {
            //should carry on
          }
        }
      } catch (err) {
        EventsHelper.debugEvent(
          "Cannot properly check minimum chrome version, userAgent: " +
            navigator.userAgent
        );
        Logger.d(
          "Cannot properly check minimum chrome version, userAgent: " +
            navigator.userAgent
        );
        break;
      }
    }
  }
}

const UnsupportedWebviewPage = ({ children }) => {
  const [show, setShow] = useState(null);
  useEffect(() => {
    const isEdgeHTML = checkEdgeHtml();
    const isOldChromium = checkChromiumVersion();
    setShow(isEdgeHTML || isOldChromium);
  }, []);

  const { locale } = useContext(LocaleContext);
  const ids = {
    titleId: "Unsupported_chromium_version_error_page_title",
    descriptionId: "Unsupported_chromium_version_error_page_copy",
    hidePrimaryButton: true,
    hideSecondaryButton: true,
  };
  return show && !ConfigHelper.isEdgeWebviewDetectionDisabled() ? (
    <>
      <Overlays />
      <ErrorPage {...ids}>
        <div className={style.buttonsContainer}>
          <MicrosoftStoreBadge
            className={style.msBadge}
            variant={locale?.slice?.(0, 2)}
          />
        </div>
      </ErrorPage>
    </>
  ) : show === null ? null : (
    children
  );
};

export default UnsupportedWebviewPage;
