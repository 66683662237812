import React from "react";

const LocaleContext = React.createContext({
  locale: undefined,
  localeCountryList: undefined,
  internationalPNPrefixes: undefined,
  // eslint-disable-next-line no-unused-vars, no-empty-function
  setCountryLocale: (config, country, userLanguage) => {},
  // eslint-disable-next-line no-unused-vars, no-empty-function
  isLocaleSupported: (locale) => {},
  // eslint-disable-next-line no-unused-vars, no-empty-function
  getDefaultMessages: (id) => {},
  // eslint-disable-next-line no-unused-vars, no-empty-function
  formatMessage: (id, values) => {},
  // eslint-disable-next-line no-unused-vars, no-empty-function
  formatPpMessage: (id, values) => {},
  // eslint-disable-next-line no-unused-vars, no-empty-function
  getPrivacyPolicyMessageKeys: () => {},
  // eslint-disable-next-line no-unused-vars, no-empty-function
  ppMessageExists: () => {},
});

export default LocaleContext;
