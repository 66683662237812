import React from "react";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { useLocation, useNavigate } from "react-router-dom";
import JasminePages from "../../routes/jasmine-pages";
import NavBar from "../../components/navbar";
import FormattedMsg from "../../locale/components/formatted-msg";
import Helpers from "../../components/helpers";
import style from "../../styles/pages/jasmine/details.module.css";
import Hero from "../../components/hero";

export default function GetDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const test = Helpers.getUrlParameter("test", location);
  return (
    <>
      <NavBar />
      <Hero
        className={style.hero}
        textId="Jasmine_landing_page_hero_module_copy"
      />
      <div
        className={`${style.container} ${style.pageContainer}`}
        data-testid={"get-details-page-container"}
      >
        <div className={style.pageContent}>
          <div className={style.descriptionSection}>
            <p>
              <FormattedMsg id="discount_promo_copy_1" />
            </p>
            <p className={style.descriptionStrong}>
              <FormattedMsg id="discount_promo_copy_2" />
            </p>
          </div>
          <div className={style.descriptionSection}>
            <p>
              <FormattedMsg id="plan_promo_copy_1" />
            </p>
            <p className={style.descriptionStrong}>
              <FormattedMsg id="plan_promo_copy_2" />
            </p>
          </div>
          <p className={style.descriptionBreak}>
            <FormattedMsg id="plan_promo_copy_3" />
          </p>
          <p className={style.descriptionBig}>
            <FormattedMsg id="plan_promo_copy_4" />
          </p>
          <Button
            className={style.mainButton}
            data-testid="next-button"
            onClick={() => {
              navigate(
                JasminePages.SubmitDeviceDetails + (test ? "?test=true" : ""),
                { replace: true }
              );
            }}
          >
            <FormattedMsg id="Next_CTA" />
          </Button>
        </div>
        <p className={style.pageNote}>
          <FormattedMsg id="discount_promo_legal_disclaimer" />
        </p>
      </div>
      <Footer />
    </>
  );
}
